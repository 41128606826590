<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.vdp-datepicker__calendar {
    bottom: 100%;
    left: -20%;
}
.input-price input {
    text-align: right;
}
</style>
<template>
    <vx-card :title="(this.statusTab != 'detail' ? (this.id ? 'Edit' : 'Create') : 'Detail') + ' Buying Price'">
        <div class="vx-row mb-6" style="width: 60%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>

        <hr>
        <h4 class="my-4">Head Data</h4>

        <div class="flex md:flex-row">
            <div class="w-full md:basis-1/2">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Buying Price Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input placeholder="Auto Generate" :value="this.data.bp_code" :readonly="true" class="w-full bg-readonly"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Buying Price Name</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <input v-model="data.bp_name" class="w-full vs-inputx vs-input--input normal hasValue" style="border: 1px solid rgba(0, 0, 0, 0.2);">
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Valid From</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row">
                            <div class="vx-col sm:w-5/12 w-full">                        
                                <datepicker name="date" :inline="false"
                                    v-model="data.valid_from"
                                    format="yyyy-MM-dd"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        data.valid_from = null
                                    }">
                                </datepicker>                        
                            </div>
                            <div class="vx-col sm:w-2/12 w-full flex text-center">
                                <span>Valid To</span>
                            </div>
                            <div class="vx-col sm:w-5/12 w-full">
                                <datepicker name="date" :inline="false"
                                    v-model="data.valid_to"
                                    format="yyyy-MM-dd"
                                    placeholder="Select Date"
                                    :cleared="() => {
                                        data.valid_to = null
                                    }">
                                </datepicker>                        
                            </div>                    
                        </div>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Created By</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row">
                            <div class="vx-col sm:w-5/12 w-full">                        
                                <vs-input :value="this.data.created_by" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>
                            <div class="vx-col sm:w-2/12 w-full flex items-center text-center">
                                <span>Approved By</span>
                            </div>
                            <div class="vx-col sm:w-5/12 w-full">
                                <vs-input :value="this.data.approved_by" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>                    
                        </div>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Create Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="vx-row">
                            <div class="vx-col sm:w-5/12 w-full">                        
                                <vs-input :value="(this.data.created_at) != null  ? formatDate(this.data.created_at) : ''" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>
                            <div class="vx-col sm:w-2/12 w-full flex items-center text-center">
                                <span>Approved Date</span>
                            </div>
                            <div class="vx-col sm:w-5/12 w-full">
                                <vs-input :value="(this.data.approved_at) != null  ? formatDate(this.data.approved_at) : ''" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </div>                    
                        </div>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Currency Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Currency
                            placeholder="Select Currency Code"
                            :value="data.currency"
                            @selected="(val) => {
                                data.currency = val
                            }"
                        ></Currency>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span class="flex items-center">Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <!-- <vs-textarea v-model="data.note" width="100%"/> -->
                        <div class="vs-component vs-con-textarea vs-textarea-primary" style="border: 1px solid rgba(0, 0, 0, 0.08); width: 100%;">
                            <textarea v-model="data.note" width="100%" class="vs-textarea"></textarea>
                        </div>
                        <div class="vx-row mb-1 pl-4" v-for="(line, index) in data.notes" :key="index">
                            <p>{{ line }}</p>
                        </div>
                    </div>
                </div>        
                <!-- <div class="vx-row mb-6" style="width: 60%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Attachment</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />
                    </div>
                </div> -->
                <!-- <div class="vx-row mb-6" style="width: 60%" v-if="this.fileUrl != ''">
                    <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <div class="op-block mt-5">
                            <vs-list>
                                <vs-list-item title="File 1">
                                    <div class="vx-input-group flex">
                                        <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                            title="Download" class="mr-1" @click="openTab(fileUrl)" />                                
                                    </div>
                                </vs-list-item>
                            </vs-list>
                        </div>
                    </div>
                </div> -->
                <div class="vx-row mb-6">
                    <div class="vx-col w-full flex items-center">
                        <span>Applicable to</span>
                    </div>            
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span class="ml-3">Supplier</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Supplier 
                            placeholder="Select supplier / vendor"
                            :value="data.supplier"
                            @selected="(val) => {
                                // if(!id){
                                    changeSupplier(val)
                                    // resetLine()
                                // }
                            }"
                        ></Supplier>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span class="ml-3">Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Warehouse 
                            placeholder="Select warehouse"
                            :value="warehouses"
                            :multiple="true"
                            @selected="(val) => {                        
                                changeWh(val)                        
                            }"
                        ></Warehouse>
                    </div>
                </div>
            </div>
            <div class="pl-6 w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <div class="w-full vx-col sm:w-5/6 upload">
                            <!-- <vs-button v-if="attachment && attachment.length > 0" class="mr-2 up btnx " type="filled"
                                color="primary" icon-pack="feather" icon="icon-plus"
                                @click="$refs.attachment.click()">Upload</vs-button>
                            <vs-button v-else class="mr-2" color="primary" icon-pack="feather" icon="icon-plus"
                                @click="$refs.attachment.click()">Upload</vs-button>
                            <vs-dropdown v-if="attachment && attachment.length > 0">
                                <vs-button class="up btn-drop" type="filled" icon="expand_more"></vs-button>
                                <vs-dropdown-menu>
                                <vs-dropdown-item>Selected files to be uploaded:</vs-dropdown-item>
                                    <vs-dropdown-item @click="handleDeleteFile(index)"
                                        v-for="(item, index) in attachment" :key="index">
                                        <vs-icon icon="delete" color="danger"></vs-icon>{{ item.name }}
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown> -->
                            <input type="file" 
                                ref="attachment" 
                                accept=".jpg,.jpeg,.png,.pdf"
                                @change="handleChangeAttachment" />
                            <vs-button class="ml-5" color="primary" @click="handleUploadAttachment()" type="filled">Upload Attachment</vs-button>
                        </div>
                    </div>
                </div>
                <div class="mb-6 vx-row w-1/2">
                    <vs-list>
                        <vs-list-item v-for="(item, index) in data.attachment" :key="index"
                            :title="item.name" class="flex">
                            <div class="flex">
                                <a @click.stop="downloadFileAwsS3(item.path)"><vs-button color="success" type="flat"
                                        icon="file_download"></vs-button></a>
                                <vs-button color="danger" @click="handleDeleteAttachment(index, item.id)"
                                    type="flat" icon="delete"></vs-button>
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>

        <hr>
        <h4 class="my-4">Lines Data</h4>
        <div class="vx-row mb-6 mt-3" v-if="this.statusTab == 'create' && !this.id">
            <vs-button class="mr-3 ml-4" color="grey" @click="handleDownload()">Download Excel</vs-button>
            <vs-button class="mr-3" color="primary" @click="handleUpload()">Upload</vs-button>            
        </div>
        <div class="vx-row mb-6 mt-3 flex items-center" v-if="this.statusTab != 'detail' && this.id">
            <p style="font-weight: 400;" class="ml-4">Include Conversion</p>
            <vs-switch v-model="switchC" class="ml-2">
                <span slot="on">On</span>
                <span slot="off">Off</span>
            </vs-switch>
            <vs-button class="mr-1 ml-1" size="small" color="grey" @click="downloadFile()">Download Excel</vs-button>
            <vs-button class="mr-1" size="small" color="primary" @click="handleUpload()">Upload Excel</vs-button>
            <vs-button class="mr-1" size="small" color="primary" type="filled" @click="showItem()">Browse Item</vs-button>
        </div>

        <vs-row :class="showTableItemUnit ? 'flex flex-wrap' : ''" class="justify-between">
            <vs-col :vs-w="showTableItemUnit ? '6' : '12'">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="11%">SKU Code</th>
                            <th width="20%">SKU Name</th>
                            <th>Note</th>
                            <th width="7%">HU</th>
                            <th>Qty From</th>
                            <th>Qty To</th>
                            <th>Amount</th>
                            <th>Valid From</th>
                            <th>Valid to</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, index) in lines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <!-- <ItemSelect
                                    placeholder="Select SKU Code"
                                    :value="line.sku_code"
                                    @selected="(val) => {
                                        line.sku_code = val
                                    }"
                                ></ItemSelect> -->
                                <vs-input v-if="line.is_parent" v-model="line.sku_code" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-if="line.is_parent" v-model="line.sku_name" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.note" class="w-full"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="line.unit" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" v-model="line.qty_from" @input="formatNumber(line, 'qty-from', index)" class="w-full"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="text" v-model="line.qty_to" @input="formatNumber(line, 'qty-to', index)" class="w-full"></vs-input>
                            </td>
                            <td class="td input-price vs-table--td" style="padding: 5px">
                                <vs-input type="text" v-model="line.price" @input="formatNumber(line, 'price', index)" @keypress="isNumber($event)" class="w-full"></vs-input>
                                <!-- <vs-input type="text" v-model="line.price" :readonly="true" @input="formatNumber(line)" class="w-full bg-readonly"></vs-input> -->
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <datepicker name="date" :inline="false"
                                    v-model="line.valid_from"
                                    format="yyyy-MM-dd"
                                    placeholder="Select Date"
                                    @input="changeDatepickerLine('from', index)"
                                    :cleared="() => {
                                        line.valid_from = null
                                    }">
                                </datepicker>                                
                                <!-- <vs-input :value="formatDate(line.valid_from)" :readonly="true" class="w-full bg-readonly"></vs-input> -->
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <datepicker name="date" :inline="false"
                                    v-model="line.valid_to"
                                    format="yyyy-MM-dd"
                                    placeholder="Select Date"
                                    @input="changeDatepickerLine('to', index)"
                                    :cleared="() => {
                                        line.valid_to = null
                                    }">
                                </datepicker>
                                <!-- <vs-input :value="formatDate(line.valid_to)" :readonly="true" class="w-full bg-readonly"></vs-input> -->
                            </td>
                            <td class="td vs-table--td" style="text-align: center">
                                <div class="flex vx-input-group">
                                    <vs-button v-if="line.sku_code != null" @click.stop="removeItemsRow(index)" size="small" color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px" title="Remove Row" />
                                    <vs-button v-if="line.sku_code != null && line.is_parent" @click.stop="addItemsRow(index)" size="small" color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td>
                            <!-- <td class="td vs-table--td" style="text-align: center">
                                <div class="flex vx-input-group">
                                    <vs-button v-if="line.dupe" @click.stop="removeItemsRow(index)" size="small" color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px" title="Remove Row" />
                                    <vs-button v-else @click.stop="addItemsRow(index)" size="small" color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </vs-col>
            <transition name="detail-fade" class="vertical-divider">
                <vs-col v-if="showTableItemUnit" vs-w="6" class="vertical-divider">
                    <div class="vx-col sm:w-1/2" v-if="showTableItemUnit">
                        <vs-button class="mt-3" color="grey" size="small" type="filled" @click="showTableItemUnit = false">Close Item</vs-button>
                    </div>
                    <TableItemUnit :draw="draw" :supplierId="data.supplier_id" @data="addItem" ref="tableItemUnit"/>
                </vs-col>
            </transition>
        </vs-row>

        <div class="vx-row mb-6 mt-3" style="width:70%" v-if="this.statusTab != 'detail' && this.id">
            <vs-button class="mr-3 ml-4" color="primary" @click="handleSubmit()">Save Changes</vs-button>
        </div>

        <!-- <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div
                style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                "> -->
                    <!-- <div v-if="this.statusTab != 'Release'">
                        <vs-button v-if="this.id" class="mr-3 mb-2" @click="handleSubmit">Update</vs-button>
                        <vs-button v-else class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    </div> -->
                <!-- </div>
            </div>
        </div> -->
        
        <vs-prompt
            @cancel="val=''"
            @accept="uploadFile"
            :active.sync="activePrompt"
            :title="'Upload Items'"
            :accept-text="'Submit'">
            <div class="con-exemple-prompt">
                <label for="fileInput" class="font-bold">File to upload</label>
                <input
                    id="fileInput"
                    name="file"
                    class="w-full inputx my-2"
                    type="file"
                    ref="file"
                    accept=".xlsx, .xls"
                    @change="handleChangeFile"
                />                
            </div>
        </vs-prompt>
    </vx-card>
</template>

<script>

import _ from "lodash";
import Date from "@/components/Date.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import Warehouse from "@/components/WarehouseCustom.vue";
import Supplier from "@/components/Supplier.vue";
import Currency from "@/components/Currency.vue";
import ItemSelect from "./component/ItemSelect.vue";
import vSelect from "vue-select";
import TableItemUnit from "./component/TableItemUnit.vue";

export default {
    components: {
        Warehouse,
        Supplier,
        Currency,
        ItemSelect,
        Date,
        vSelect,
        Datepicker,
        TableItemUnit
    },
    data: () => ({
            id: null,
            activePrompt : false,
            file: null,
            decimal_point: 0,
            data: {
                bp_id: null,
                bp_code: null,
                bp_name: null,
                valid_from: null,
                valid_to: null,
                created_by: null,
                created_at: null,
                approved_by: null,
                approved_at: null,
                currency: null,
                note: null,
                notes: [],
                supplier: {},
                supplier_id: null,
                supplier_name: null,
                supplier_code: null,
                attachment: [],
            },
            lines: [],
            warehouses: [],
            statusReadonly: false,
            statusTab: null,
            is_approval: false,
            switchC: false,
            showTableItemUnit: false,
            attachment: [],
    }),
    watch: {
        switchC(newVal, oldVal) {
            this.getData()
        },
        "data.supplier_id": function(newVal, oldVal) {
            // this.lines = []
            // this.initLine()
            console.log("===========================")
            console.log("old ", oldVal)
            console.log("new ", newVal)

            if (oldVal != null && oldVal != newVal) {
                this.resetLine()
            }
        },
        "data.valid_from": function() {
            console.log("valid_from : ", this.data.valid_from)
            if (moment(this.data.valid_from) > moment(this.data.valid_to)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Valid from cannot > valid to",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                this.$nextTick(() => this.data.valid_from = moment().format('YYYY-MM-DD'))
            } else {
                var i;
                for (i = 0; i < this.lines.length; i++) {
                    this.lines[i].valid_from = moment(this.data.valid_from).format('YYYY-MM-DD')
                }
            }
            
        },
        "data.valid_to": function() {
            console.log("valid_to : ", this.data.valid_to)
            if (moment(this.data.valid_to) < moment(this.data.valid_from)) {
                this.$vs.notify({
                    title: "Error",
                    text: "Valid to cannot < valid from",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                this.$nextTick(() => this.data.valid_to = this.data.valid_from)
            } else {
                var i;
                for (i = 0; i < this.lines.length; i++) {
                    this.lines[i].valid_to = moment(this.data.valid_to).format('YYYY-MM-DD')
                }
            }
        }
    },
    mounted() {
        this.getDataRounding();
        this.id = this.$route.params.id
        this.statusTab = this.$route.params.tab
        if(this.statusTab == undefined){
            this.statusTab = "create"
        }
        console.log("tab : "+this.statusTab)
        if (this.$route.params.is_approval){
            this.is_approval = true
        }
        if (this.id) {
            this.getData()
        }else{
            const newItem = {
                sku_code: null,
                sku_name: null,
                unit: null,
                qty_from: null,
                qty_to: null,
                price: null,
                valid_from: null,
                valid_to: null,
                item_id: null,
                unit_id: null,
                item_unit_id: null,
                is_parent: true,
                has_child: false
            }
            this.lines.push(newItem)
        }
    },
    methods: {
        resetLine(){
            console.log("reset line")
            this.lines = []
            this.initLine()
            this.showTableItemUnit = false
        },
        handleDeleteFile(index) {
            var files = Array.from(this.attachment)
            files.splice(index, 1)
            this.attachment = files;
        },
        deleteFile(id) {
            this.$vs.loading();
            this.$http.delete(`${this.$store.state.master.buyingPrice.baseUrlPath}/${this.id}/attachment/${id}`)
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    // this.form.attachment = resp.data;
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            });
        },
        handleChangeAttachment(event) {
            this.attachment = event.target.files ? event.target.files : [];
        },
        handleDeleteAttachment(index, id) {
            var files = Array.from(this.data.attachment)
            files.splice(index, 1)
            this.data.attachment = files;
            if (id) {
                this.deleteFile(id);
            }
        },
        handleUploadAttachment() {
            // this.data.attachment.push({})
            // console.log(this.data.attachment)
            let doUpload = true
            if (this.data.attachment == null) {
                this.data.attachment = []
            }
            
            var formData = new FormData();
            if (this.attachment.length > 0) {
                Array.from(this.attachment).forEach(item => {
                    if (item.size <= 5000000){
                        formData.append("files", item);
                    } else {
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: 'Attachment max 5MB',
                        });
                        doUpload = false
                    }
                });
            } else {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: 'Empty file',
                });
                doUpload = false
            }
            if (doUpload) {
                this.$vs.loading();
                this.$http
                .post(`${this.$store.state.master.buyingPrice.baseUrlPath}/upload-file/`+this.id, formData)
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        this.data.attachment.push(resp.data)
                        console.log("======attachment yang seharusnya muncul")
                        console.log(this.data.attachment)
                        this.$refs.fileupload.value = null;
                    } else {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "confirm",
                            color: "danger",
                            title: `Error`,
                            text: `${resp.code} : ${resp.message}`,
                        });
                    }
                });
            }
            this.attachment = []
        },
        changeWh(val){
            this.warehouses = val            
        },
        changeSupplier(val){
            console.log("change supplier")

            if (val == null) {
                console.log("change supplier from change")
                this.resetLine()
            } else {
                this.data.supplier_id = val.id
                this.data.supplier_name = val.name
                this.data.supplier_code = val.code
            }
        },
        checkIsExist(obj, list){
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i].item_unit_id === obj.item_unit_id) {
                    return true;
                }
            }
            return false;
        },
        getDataRounding() {
            console.log("get data rounding")
            this.$vs.loading();
            this.$http
            .get(this.$store.state.setting.rounding.baseUrlPath, {
                params: {
                    rounding_type: "DBP"
                }
            })
            .then((resp) => {
                console.log(resp)
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.decimal_point = resp.data.decimal_point
                }
            });
        },
        getData() {
            this.$vs.loading();
            // this.data = {};
            this.warehouses = [];
            this.lines = [];
            this.$http
            .get(this.$store.state.master.buyingPrice.baseUrlPath + "/" + this.id)
            .then((resp) => {
                console.log(resp)
                this.$vs.loading.close();
                if (resp.code == 200) {
                    var data_head = resp.data.data_head[0]
                    var created = resp.data.created[0]
                    this.data.bp_id = data_head.id
                    this.data.bp_code = data_head.code
                    this.data.bp_name = data_head.name
                    this.data.valid_from = moment(data_head.valid_from).format('YYYY-MM-DD')
                    this.data.valid_to = moment(data_head.valid_to).format('YYYY-MM-DD')
                    this.data.currency = data_head.currency_code
                    this.data.created_at = data_head.created_at
                    this.data.created_by = created.name
                    this.data.supplier_id = data_head.supplier_id
                    this.data.supplier_name = data_head.supplier_name
                    this.data.supplier_code = data_head.supplier_code
                    this.data.notes = data_head.notes.split(";")
                    this.data.supplier = {
                        "id": data_head.supplier_id,
                        "code": data_head.supplier_code,
                        "name": data_head.supplier_name,
                        "label": data_head.supplier_code + " " + data_head.supplier_name
                    }
                    console.log("ini ngeset ulang data attachment")
                    this.data.attachment = resp.data.attachment

                    var approval = resp.data.approval
                    if (data_head.status == "released" || data_head.status == "expired") {
                        if (approval.length != 0) {
                            this.data.approved_by = approval[0].username
                            this.data.approved_at = approval[0].updated_at
                        }                            
                    }

                    resp.data.data_wh.forEach((el, idx) => {
                        const newItem = {
                            id: el.warehouse_id,
                            label: el.code +" "+ el.name
                        };
                        this.warehouses.push(newItem);
                    });

                    var isConversion = this.switchC

                    const skuTracker = {};

                    // Iterasi pertama untuk menemukan id terkecil untuk setiap sku_code
                    resp.data.data_line.forEach((el) => {
                        if (isConversion || (!isConversion && el.is_conversion == 0)) {
                            const key = `${el.sku_code}_${el.unit_name}`;
                            if (!skuTracker[key] || el.id < skuTracker[key].id) {
                                skuTracker[key] = el;
                            }
                        }
                    });

                    resp.data.data_line.forEach((el, idx) => {
                        console.log('LINE',el)
                        if (isConversion || (!isConversion && el.is_conversion == 0)){
                            const key = `${el.sku_code}_${el.unit_name}`;
                            const newItem = {
                                sku_code: el.sku_code,
                                sku_name: el.item_name,
                                note: el.note,
                                unit: el.unit_name,
                                qty_from: el.qty_from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                qty_to: el.qty_to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                price: el.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                valid_from: el.valid_from,
                                valid_to: el.valid_to,
                                item_unit_id: parseFloat(el.item_unit_id),
                                parent_item_unit_id: el.item_unit_id,
                                is_parent: skuTracker[key].id === el.id,
                                is_conversion : el.is_conversion
                            }
                            this.lines.push(newItem)
                        }
                    })
                }
            });
        },
        showItem(){
            console.log("supplier id ", this.data.supplier_id)
            console.log("supplier name ", this.data.supplier_name)
            console.log("warehouse ", this.warehouses)

            if (this.data.supplier_id == null){
                this.$vs.notify({
                    title: "Error",
                    text: "Select supplier first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                this.showTableItemUnit = false
            } else {
                this.showTableItemUnit = true
                this.draw += 1
                console.log("draw ", this.draw)
            }
        },
        addItem(data) {
            console.log("add data ", data)
            const newItem = Object.assign({})
            newItem.is_parent = true
            newItem.has_child = false
            newItem.sku_code = data.sku_code
            newItem.sku_name = data.item_name
            newItem.unit = data.unit_name
            newItem.qty_from = "1"
            newItem.qty_to = "9,999,999"
            newItem.price = 0
            newItem.valid_from = this.data.valid_from
            newItem.valid_to = this.data.valid_to
            newItem.item_id = data.item_id
            newItem.unit_id = data.unit_id
            newItem.item_unit_id = data.item_unit_id
            console.log("array 0 ", this.lines[0].sku_code)
            console.log("array length ", this.lines.length)
            console.log("new item", newItem)
            if (this.lines.length == 1 && this.lines[0].sku_code == null) {
                console.log("masuk 1")
                this.lines = [newItem]                
            } else {
                console.log("masuk 2")
                if (this.checkIsExist(newItem, this.lines)) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Item already exist",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x",
                    });
                } else {
                    this.lines.splice(this.lines.length + 1, 0, newItem)
                }
            }
        },
        addItemsRow(index) {
            this.lines[index].has_child = true
            const newItem = Object.assign({}, this.lines[index])
            newItem.is_parent = false
            newItem.parent_item_unit_id = this.lines[index].item_unit_id
            newItem.sku_code = this.lines[index].sku_code
            newItem.sku_name = this.lines[index].sku_name
            newItem.unit = this.lines[index].unit
            newItem.qty_from = "1"
            newItem.qty_to = "9999999"
            newItem.price = 0
            newItem.valid_from = this.lines[index].valid_from
            newItem.valid_to = this.lines[index].valid_to
            newItem.item_id = this.lines[index].item_id
            newItem.unit_id = this.lines[index].unit_id
            newItem.item_unit_id = this.lines[index].item_unit_id
            this.lines.splice(index + 1, 0, newItem)            
        },
        initLine(){
            const newItem = {
                sku_code: null,
                sku_name: null,
                unit: null,
                qty_from: null,
                qty_to: null,
                price: null,
                valid_from: null,
                valid_to: null,
                item_id: null,
                unit_id: null,
                item_unit_id: null,
                is_parent: true,
                parent_item_unit_id: 0
            }
            this.lines.push(newItem)
        },
        removeItemsRow(index) {
            if (this.lines[index].is_parent) {
                var i;
                for (i = this.lines.length-1; i >= 0; i--) {
                    console.log("parent item unit id ", this.lines[i].parent_item_unit_id)
                    console.log("price ", this.lines[i].price)
                    if (this.lines[i].parent_item_unit_id === this.lines[index].item_unit_id) {
                        console.log("delete index ", i)
                        console.log("delete price ", this.lines[i].price)
                        this.lines.splice(i, 1)
                    }
                }
            }
            this.lines.splice(index, 1)

            if (this.lines.length == 0) {
                this.initLine()
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        formatNumber(line, type, index) {
            var amount = 0
            if (type == "price") {
                if (this.lines[index].price.replace(/,/g, "").length < 16) {
                    var str5 = this.lines[index].price.toString().split(".");
                    if (str5.length > 2) {
                        this.lines[index].price = this.lines[index].price
                        .toString()
                        .replace(/\.+$/, "");
                    }

                    if (str5.length == 2) {
                        if (str5[1].length > this.decimal_point) {
                        this.lines[index].price = this.lines[index].price
                            .toString()
                            .slice(0, -1);
                        }
                    }

                    this.lines[index].price = (this.lines[index].price.replace(/,/g, ""))
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    this.lines[index].price = this.lines[index].price
                        .toString()
                        .slice(0, -1);
                }
            } else if (type == "qty-from") {
                console.log("masuk format")
                amount = parseFloat(line.qty_from.replace(/[^\d.-]/g, ''))
                if (!isNaN(amount)){
                    line.qty_from = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    line.qty_from = 0
                }
            } else if (type == "qty-to") {
                amount = parseFloat(line.qty_to.replace(/[^\d.-]/g, ''))
                if (!isNaN(amount)){
                    line.qty_to = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    line.qty_to = 0
                }
            }
        },
        handleBack() {
            if(!this.is_approval){
                this.$router.push({
                    name: this.$store.state.master.buyingPrice.baseRouterName,
                });
            }else {
                this.$router.push({
                    name: this.$store.state.master.buyingPrice.baseRouterName+".approval",
                });
            }
        },
        changeDatepickerLine(type, index) {
            console.log("datepicker type ", type)
            console.log("datepicker index ", index)
            let statusError = false
            let messageError = ""
            if (type == "from") {
                if (moment(this.lines[index].valid_from) < moment(this.data.valid_from)) {
                    statusError = true
                    messageError = "Valid from line cannot be earlier than valid from header"
                } else if (moment(this.lines[index].valid_from) > moment(this.data.valid_to)) {
                    console.log('TESSSS')
                    statusError = true
                    messageError = "Valid from line cannot be later than valid to header"
                }

                if (statusError) {
                    this.$nextTick(() => this.lines[index].valid_from = this.data.valid_from)
                    this.$vs.notify({
                        title: "Error",
                        text: messageError,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x",
                    });
                }
                this.lines[index].valid_from = moment(this.lines[index].valid_from).format("YYYY-MM-DD")
                console.log("datepicker ", this.lines[index].valid_from)
            } else if (type == "to") {
                if (moment(this.lines[index].valid_to).format("YYYY-MM-DD") > moment(this.data.valid_to).format("YYYY-MM-DD")) {
                    statusError = true
                    messageError = "Valid to line cannot be later than valid to header"
                } else if (moment(this.lines[index].valid_to).format("YYYY-MM-DD") < moment(this.data.valid_from).format("YYYY-MM-DD")) {
                    statusError = true
                    messageError = "Valid to line cannot be earlier than valid from header"
                }

                if (statusError) {
                    this.$vs.notify({
                        title: "Error",
                        text: messageError,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x",
                    });
                    this.$nextTick(() => this.lines[index].valid_to = this.data.valid_to)
                }
                this.lines[index].valid_to = moment(this.lines[index].valid_to).format("YYYY-MM-DD")
                console.log("datepicker ", this.lines[index].valid_to)
            }
            

        },
        downloadFile(){
            this.$vs.loading();
            this.$http
            .get(this.$store.state.master.buyingPrice.baseUrlPath + "/excel-edit", {
                params: {
                    buyingPriceId: this.data.bp_id,
                    supplierId: this.data.supplier_id,
                    isConversion: this.switchC
                },
                responseType: "blob",
            })
            .then((resp) => {
                this.$vs.loading.close();
                if (resp.type == "application/json"){
                    this.$vs.notify({
                        title: "Error",
                        text: "Items for supplier "+this.data.supplier_name+" not found",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x",
                        time: "10000",
                    });
                }else{                    
                    var fileURL = window.URL.createObjectURL(resp);
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        "download", "edit-buying-price.xlsx"
                    );                
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
        },
        handleUpload() {
            this.activePrompt = true
            this.file = null
        },
        handleChangeFile(event) {
            this.file = event.target.files ? event.target.files[0] : null;
            if (this.file) {
                const reader = new FileReader();
                reader.readAsBinaryString(this.file);
            }
        },
        uploadFile() {
            if (!this.file) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                    time: "10000",
                });
            } else {
                let form = new FormData();
                form.append("file", this.file);
                form.append("buyingPriceID", this.id);
                form.append("supplierID", this.data.supplier_id);

                this.$vs.loading();
                this.$http
                    .post(this.$store.state.master.buyingPrice.baseUrlPath + "/import-edit", form)
                    .then((resp) => {                        
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.getData()
                            this.$vs.notify({
                                title: "Success",
                                text: "File Uploaded Successfully",
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check",
                            });
                        } else {                            
                            this.$vs.notify({
                                title: "Error",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x",
                                time: "10000",
                            });
                        }                    
                    });
            }
        },
        // handleChangeAttachment(event) {
        //     this.file = event.target.files ? event.target.files[0] : null;
        // },
        handleSubmit() {
            let formData = new FormData();
            this.data.valid_from = moment(this.data.valid_from).format('YYYY-MM-DD')
            this.data.valid_to = moment(this.data.valid_to).format('YYYY-MM-DD')
            let errorMessage = ""
            this.lines.forEach((line, tableIndex) => {
                line.valid_from = moment(line.valid_from).format('YYYY-MM-DD')
                line.valid_to = moment(line.valid_to).format('YYYY-MM-DD')
                if(parseFloat(line.qty_from) > parseFloat(line.qty_to)){
                    errorMessage = `Qty from cannot be greater than qty to in sku code ${line.sku_code}`;
                }
            });
            console.log("DATA",JSON.stringify(this.data))
            console.log("LINE",JSON.stringify(this.lines))
            formData.append("data", JSON.stringify(this.data))
            formData.append("warehouses", JSON.stringify(this.warehouses))
            formData.append("lines", JSON.stringify(this.lines))

            if (this.data.bp_name == ""){
                this.$vs.notify({
                    title: "Error",
                    text: "Buying price name is required",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                return
            } else if(errorMessage != ""){
                this.$vs.notify({
                    title: "Error",
                    text: errorMessage,
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                return
            } else if (this.warehouses.length == 0){
                this.$vs.notify({
                    title: "Error",
                    text: "Warehouses is required",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x",
                });
                return
            }

            this.$vs.loading();
            this.$http
                .post(this.$store.state.master.buyingPrice.baseUrlPath, formData)
                .then((resp) => {                        
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.handleBack()
                        this.$vs.notify({
                            title: "Success",
                            text: resp.message,
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {                            
                        this.$vs.notify({
                            title: "Error",
                            text: resp.message,
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x",
                            time: "10000",
                        });
                    }                    
                });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    computed:{
        formatDate: () => {
            return (val) => {
                if (!val || !moment.utc(val).isValid()) {
                    return ''; // Return empty string for invalid date or empty input
                }
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formattedNumber() {
            const formattedValue = parseFloat(this.rawInput).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });

            return formattedValue;
        },
    }    
}
</script>
<style>
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 500px;
  padding: 5px;
}

.upload {
    display: flex;
}

.upload button.up {
    margin: 0px !important;
}

.upload button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}

.upload button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
}
</style>